import axios from "axios";

export function getElecSections(params) {
    return axios.post("./index.php", {
        function: "getElecSections",
        ...params,
    });
}

export function getElecSection(params) {
    return axios.post("./index.php", {
        function: "getElecSection",
        ...params,
    });
}

export function getSignPackage() {
    return axios.post("./index.php", {
        function: "getSignPackage",
    });
}

export function getElecHandbooks(params) {
    return axios.post("./index.php", {
        function: "getElecHandbooks",
        ...params,
    });
}

export function getElecHandbookByProjectCode(params) {
    return axios.post("./index.php", {
        function: "getElecHandbookByProjectCode", ...params,
    });
}

export function getLoginProjects() {
    return axios.post("./index.php", {
        function: "getLoginProjects",
    });
}
